import { Link, PageProps } from 'gatsby';
import React from 'react';

import AspectBox from '../../components/AspectBox';
import Img from '../../components/Img';
import Layout from '../../components/Layout';
import PrismicRichText from '../../components/PrismicRichText';
import { Post as PostType } from '../../types/post';
import { buildImgixUrlWithAspectRatio } from '../../util/images';
import { richTextAsString } from '../../util/rich-text-as-string';

import './Post.scss';

export interface PostPageContext {
  post: PostType;
}

const Post: React.FC<PageProps<unknown, PostPageContext>> = ({ pageContext: { post } }) => (
  <Layout
    mainClassName="post"
    title={post.title}
    meta={{
      description: post.description,
      image: post.mainPhotos[0],
    }}
  >
    <div className="post-heading">
      <div className="container">
        <h2>{post.title}</h2>
        <p>{richTextAsString(post.description)}</p>
      </div>
    </div>

    <div className="post-body">
      <div className="container">
        {post.body.map((slice, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {slice.type === 'text' && <PrismicRichText content={slice.content} />}

            {slice.type === 'experience' && (
              <div className="post-experience">
                <Link to={slice.experience.path}>
                  <AspectBox heightRatio={1 / 0.75}>
                    <Img src={buildImgixUrlWithAspectRatio(slice.experience.mainPhoto.landscape.url, 1 / 0.75, 400)} />
                  </AspectBox>

                  <div>
                    <h2>{slice.experience.title}</h2>
                    <h5>{slice.experience.name}</h5>
                  </div>
                </Link>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  </Layout>
);

export default Post;
